import { useSheet } from '@/hooks/useSheet';
import React, { useEffect, useState } from 'react';
import { LancamentosService } from '@/services/endpoints/lancamentos';

interface SheetProps {
  sheetData: any;
  totals: any;
  disabled: any;
  dataTime: Root;
  setTotals: any;
  totalHoras: any;
  dataToPost: any;
  totalSumAll: any;
  setDataToPost: any;
  openAlert: boolean;
  disabledButton: any;
  setDisabledButton: any;
  actionTotalTimes: {
    [key: string]: number;
  }
  handleGetSheetUser: () => any;
  setTotalSumAll: React.Dispatch<any>;
  setDataTime: React.Dispatch<React.SetStateAction<Root>>;
  setActionTotalTimes: React.Dispatch<React.SetStateAction<{
    [key: string]: number;
  }>>
  setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TimeContext = React.createContext({} as SheetProps);

interface IProps {
  children: React.ReactNode;
}

export interface Hora {
  datetime: string;
  hour: string;
  id?: any;
}
export interface Action {
  description: string;
  hours: Hora[];
  id: any;
}
export interface Root {
  name: string;
  actions: Action[];
  totals?: any;
  hours?: any;
  id?: any;
}

export const TimeProvider: React.FC<IProps> = ({ children }) => {

  const [openAlert, setOpenAlert] = useState(false)
  const [totalSumAll, setTotalSumAll] = useState<any>();
  const [actionTotalTimes, setActionTotalTimes] = useState<{
    [key: string]: number;
  }>({});
  const [dataTime, setDataTime] = useState<Root>({} as Root)
  const [sheetData, setSheetData] = useState([])
  const [totals, setTotals] = useState<any[]>([])
  const [dataToPost, setDataToPost] = React.useState([])
  const [totalHoras, setTotalHoras] = useState<any[]>([])
  const [disabled, setDisabled] = useState<boolean>(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(false)

  const { currentMonth, selectedYear } = useSheet()
  const { getHoursSheet } = new LancamentosService()

  const handleGetSheetUser = () => {
    const id = localStorage.getItem("USER_LOG_ID")

    if (currentMonth?.mesNumero && selectedYear && id) {
      getHoursSheet(id, { year: selectedYear, month: currentMonth?.mesNumero }).then((data) => {
        console.log('getHoursSheet', data)
        setSheetData(data?.data?.table)
        setDisabled(data?.data?.bloqueado)
        setTotals(data?.data?.total)
        setTotalHoras(data?.data?.totalhoras)
      }).catch((error) => {
        console.log(error);
      })
    }
  }

  useEffect(() => {
    setDataToPost([])
  }, [currentMonth, selectedYear])

  useEffect(() => {
    console.log(dataToPost, 'dataToPost');

  }, [dataToPost])
  const value = {
    totals,
    disabled,
    setTotals,
    sheetData,
    dataTime,
    openAlert,
    dataToPost,
    totalHoras,
    totalSumAll,
    setDataTime,
    setOpenAlert,
    setTotalHoras,
    setDataToPost,
    disabledButton,
    setTotalSumAll,
    actionTotalTimes,
    setDisabledButton,
    handleGetSheetUser,
    setActionTotalTimes,
  } as SheetProps;


  useEffect(() => {
    handleGetSheetUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, currentMonth])

  return (
    <TimeContext.Provider value={value}>{children}</TimeContext.Provider>
  );
};